import React from 'react';
import { graphql, Link } from 'gatsby';

import Header from '../components/header';

const Route = ({ pageContext, data, location }) => {
  const { current: route } = data;

  const {
    fields: { related: related },
    frontmatter: { steps: steps }
  } = route;

  return (
    <article className="page route">
      <Header
        title={route.frontmatter.title}
        back="/rutas"
        intro={route.frontmatter.intro}
        path={location.pathname}
      />

      <ul className="route-steps">
        {steps.map((step, i) => {
          return (
            <li
              key={`step_${i}`}
              className={`step${
                i === 0
                  ? ' big initial'
                  : i === steps.length - 1
                  ? ' big final'
                  : ''
              }`}
            >
              <div className="icon">{i + 1}</div>
              <div className="info">
                <h3>{step.title}</h3>
                <p>{step.description}</p>
              </div>
              <div className="related">
                {related[i] ? <Link to={related[i]}>+ info</Link> : null}
              </div>
            </li>
          );
        })}
      </ul>
    </article>
  );
};

export default Route;

export const query = graphql`
  query($slug: String!) {
    current: markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        related
      }
      frontmatter {
        title
        intro
        steps {
          title
          description
          point
        }
      }
    }
  }
`;
